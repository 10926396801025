import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import AppCover from "./components/common/App";

function App() {
  return <AppCover />;
}

export default App;
