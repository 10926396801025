import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import authService from "../../services/authService";
import { Button, Modal, Table } from "react-bootstrap";

class PrehledRezervaci extends Component {
  state = {};

  componentDidMount = () => {
    const user = authService.getCurrentUser();
    const { login, hash } = this.props.match.params;
    if (user && user.login === login) {
      this.nahrajTerminy();
    } else {
      this.prihlasUzivatele();
    }
  };

  nahrajTerminy = async () => {
    const terminy = await httpService.post(
      config.apiEndpoint + "covid/terminyUzivatel.php"
    );
    this.setState({ terminy: terminy.data.terminy, loaded: true });
  };

  prihlasUzivatele = async () => {
    const { login, hash } = this.props.match.params;
    const uzivatel = await httpService.post(
      config.apiEndpoint + "user/prihlasUzivatele.php",
      { login, hash }
    );
    localStorage.setItem("token", uzivatel.data);
    window.location.reload();
  };

  zrusitRezervaci = async (rezervace_id, confirm) => {
    if (confirm) {
      const message = await httpService.post(
        config.apiEndpoint + "covid/zrusitRezervaci.php",
        { rezervace_id }
      );
      this.nahrajTerminy();
      this.setState({ message: message.data.message });
    } else {
      if (window.confirm("Skutečně zrušit rezervaci?")) {
        this.zrusitRezervaci(rezervace_id, true);
      }
    }
  };

  render() {
    const { loaded, terminy, message } = this.state;
    return (
      <React.Fragment>
        <Modal show={message} onHide={() => this.setState({ message: null })}>
          <Modal.Header>Zpráva</Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ message: null })}>OK</Button>
          </Modal.Footer>
        </Modal>
        <div
          className="header"
          style={{
            backgroundImage: "url('bg.jpg')",
            backgroundSize: "cover",
            color: "#fff",
          }}
        >
          <div className="container">
            <h3>Registrace na antigenní testy</h3>
          </div>
        </div>
        <div className="container pt-5 pb-5">
          {loaded ? (
            <React.Fragment>
              <h3>Vaše rezervace</h3>
              {terminy && terminy.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Odběrové místo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {terminy.map((t) => (
                      <tr key={t.rezervace_id}>
                        <td>{t.casRezervace}</td>
                        <td>{t.nazev}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => this.zrusitRezervaci(t.rezervace_id)}
                          >
                            Zrušit rezervaci
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-danger">
                  Nemáte v rezervacích žádný rezervovaný termín testu...
                </div>
              )}
            </React.Fragment>
          ) : (
            "Loading..."
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PrehledRezervaci;
