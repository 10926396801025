import React, { Component } from "react";
import config from "../../config.json";
import { ToastContainer } from "react-toastify";
import { Navbar, Nav, Modal, Button } from "react-bootstrap";
import { HashRouter, Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import authService from "../../services/authService";
import UserLogin from "./UserLogin";
import PrehledRezervaci from "../Covid/PrehledRezervaci";

class AppCover extends Component {
  state = {};
  render() {
    const user = authService.getCurrentUser();
    const { prihlasit } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <Modal
          show={prihlasit}
          onHide={() => this.setState({ prihlasit: false })}
        >
          <Modal.Header closeButton>Přihlášení</Modal.Header>
          <Modal.Body>
            <UserLogin />
          </Modal.Body>
        </Modal>
        <Navbar bg="light" expand="lg">
          <div className="container">
            <Navbar.Brand href="/">
              <img src={config.logo} style={{ height: "32px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <div>
                {user && user.login ? (
                  <React.Fragment>
                    <span className="mr-3">{user.jmeno}</span>
                    <Button
                      onClick={() => {
                        authService.logout();
                        window.location.reload();
                      }}
                    >
                      Odhlásit
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button onClick={() => this.setState({ prihlasit: true })}>
                    Přihlásit
                  </Button>
                )}
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <HashRouter>
          <Switch>
            <Route
              path="/zrusitRezervaci/:login/:id/:hash"
              component={PrehledRezervaci}
            />
            <Route path="/registrace/:tab" component={HomePage} />
            <Route path="/" component={HomePage} />
          </Switch>
        </HashRouter>
        <div style={{ backgroundColor: "#333", color: "#FFF" }}>
          <div className="container pt-5 pb-5">
            <h4>Provozovatel</h4>
            Slatinné lázně Třeboň s. r. o.
            <br />
            Lázeňská 1001
            <br />
            379 01 Třeboň
            <br />
            IČ: 25179896
            <br />
            DIČ: CZ25179896
            <br />
            Zapsaná v obchodním rejstříku Krajského soudu v Českých Budějovicích
            oddílu C, vložce 8137
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AppCover;
