import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { Modal } from "react-bootstrap";

class NovinkyPrehled extends Component {
  state = {};

  componentDidMount = () => {
    this.nahrajNovinky();
  };

  nahrajNovinky = async () => {
    const novinky = await httpService.post(
      config.apiEndpoint + "novinky/vypis.php",
      { verejna: true }
    );
    this.setState({ novinky: novinky.data.novinky, loaded: true });
  };

  snowNovinka = (novinka_id) => {
    this.setState({ novinka_id });
  };

  render() {
    const { novinky, loaded, novinka_id } = this.state;
    let cols = 12;
    let novinka = {};
    if (novinka_id) {
      novinka = novinky.find((n) => n.novinka_id === novinka_id);
    }
    if (loaded && novinky) {
      switch (novinky.length) {
        case 1:
          cols = 12;
          break;
        case 2:
          cols = 6;
          break;
        case 3:
          cols = 4;
          break;
        default:
          cols = 3;
          break;
      }
    }
    return (
      <React.Fragment>
        {loaded && novinky && novinky.length > 0 && (
          <React.Fragment>
            <Modal
              show={novinka_id}
              onHide={() => this.setState({ novinka_id: false })}
              dialogClassName="modal-90w"
            >
              <Modal.Header closeButton>
                {novinka && novinka.nadpis}
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: novinka && novinka.uvod,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: novinka && novinka.novinka,
                  }}
                />
              </Modal.Body>
            </Modal>
            <div
              className="mb-3 pb-3 row"
              style={{ borderBottom: "solid 1px #eee" }}
            >
              {novinky.map((n) => (
                <div className={"col-md-" + cols} key={n.novinka_id}>
                  <h4>
                    <a
                      href="javascript:;"
                      onClick={() => this.snowNovinka(n.novinka_id)}
                    >
                      {n.nadpis}
                    </a>
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: n.uvod,
                    }}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default NovinkyPrehled;
