import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { Tabs, Tab, Card, Button } from "react-bootstrap";
import authService from "../../services/authService";
import { Modal } from "react-bootstrap";

class CovidTerminy extends Component {
  state = {};

  timer = false;

  componentDidMount = () => {
    this.nactiTerminy();
  };

  componentDidUpdate = () => {
    const { firma_id, login } = this.state;
    if (
      (firma_id && firma_id !== this.props.firma_id) ||
      (login && login !== this.props.login) ||
      (firma_id === this.props.firma_id && !login && this.props.login)
    ) {
      this.nactiTerminy();
    }
  };

  zmenaDatumu = async (datum) => {
    await this.setState({ datum });
    this.nactiTerminy();
  };

  nactiTerminy = async () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const { firma_id, login } = this.props;
    const { datum } = this.state;
    const terminy = await httpService.post(
      config.apiEndpoint + "covid/terminyWeb.php",
      { firma_id, login, datum }
    );
    this.setState({
      firma_id,
      login,
      terminy: terminy.data.terminy,
      datum: terminy.data.datum,
      loaded: true,
    });
    this.timer = setTimeout(() => this.nactiTerminy(), 10000);
  };

  rezervovat = async (login, ordinace_id, cas) => {
    const { onChange } = this.props;
    const message = await httpService.post(
      config.apiEndpoint + "covid/novaRezervace.php",
      {
        login,
        ordinace_id,
        cas,
      }
    );
    this.setState({ message: message.data.message });
    this.nactiTerminy();
    if (onChange) {
      onChange();
    }
  };

  zrusitRezervaci = async (rezervace_id) => {
    const { onChange } = this.props;
    const message = await httpService.post(
      config.apiEndpoint + "covid/zrusitRezervaci.php",
      { rezervace_id }
    );
    this.nactiTerminy();
    this.setState({ message: message.data.message });
    if (onChange) {
      onChange();
    }
  };

  render() {
    const { firma_id, login, terminy, datum, loaded, message } = this.state;
    const user = authService.getCurrentUser();
    return (
      <React.Fragment>
        <Modal show={message} onHide={() => this.setState({ message: null })}>
          <Modal.Header>Zpráva</Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ message: null })}>OK</Button>
          </Modal.Footer>
        </Modal>
        {loaded ? (
          <div>
            <small>Zvolte datum testu</small>
            <input
              type="date"
              className="form-control"
              style={{ width: "300px" }}
              value={datum}
              onChange={(e) => this.zmenaDatumu(e.target.value)}
            />
            {terminy && terminy.length > 0 ? (
              <div className="pt-3">
                <Tabs>
                  {terminy.map((t) => (
                    <Tab
                      key={"ordinace_" + t.ordinace_id + "_" + datum}
                      eventKey={"ordinace_" + t.ordinace_id + "_" + datum}
                      title={t.ordinace}
                    >
                      <div className="row pt-3">
                        {t.terminy.map((c) => (
                          <div className="col-md-4 pb-2" key={c.cas}>
                            <Card>
                              <Card.Header>{c.casFormatted}</Card.Header>
                              <Card.Body>
                                <div className="text-center">
                                  {c.rezervace_id ? (
                                    <React.Fragment>
                                      <div>
                                        {c.login === user.login
                                          ? "Vaše rezervace"
                                          : c.jmeno + " (*" + c.rocnik + ")"}
                                      </div>
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          this.zrusitRezervaci(c.rezervace_id)
                                        }
                                      >
                                        Zrušit rezervaci
                                      </Button>
                                    </React.Fragment>
                                  ) : login ? (
                                    <Button
                                      onClick={() =>
                                        this.rezervovat(
                                          login,
                                          c.ordinace_id,
                                          c.cas
                                        )
                                      }
                                    >
                                      Rezervovat
                                    </Button>
                                  ) : (
                                    <span className="text-success">
                                      Volný termín
                                    </span>
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            ) : (
              <div className="mt-3 alert alert-danger">
                Na tento den se není možné registrovat.
              </div>
            )}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </React.Fragment>
    );
  }
}

export default CovidTerminy;
