import React, { Component } from "react";
import authService from "../../services/authService";
import UserLogin from "../common/UserLogin";
import UserRegistrace from "../common/UserRegistrace";
import { Button } from "react-bootstrap";
import httpService from "../../services/httpService";
import config from "../../config.json";

class FiremniTestovani extends Component {
  state = { novaFirma: {} };

  componentDidMount = () => {
    const user = authService.getCurrentUser();
    let novaFirma = {};
    if (user && user.login) {
      novaFirma = {
        emailKomunikace: user.login,
        telefonKomunikace: user.mobil,
      };
    }
    this.setState({ novaFirma });
  };

  zmenaFirma = (attr, val) => {
    let { novaFirma } = this.state;
    novaFirma[attr] = val;
    this.setState({ novaFirma });
  };

  novaFirma = async () => {
    const { novaFirma } = this.state;
    await httpService.post(config.apiEndpoint + "firmy/novaFirma.php", {
      novaFirma,
    });
    this.setState({ novaFirma: {} });
    window.location = "#/registrace/firemni-testovani";
    window.location.reload();
  };

  checkFirma = () => {
    const { novaFirma } = this.state;
    return (
      novaFirma.nazevSpolecnosti &&
      novaFirma.nazevSpolecnosti !== "" &&
      novaFirma.ic &&
      novaFirma.ic !== "" &&
      novaFirma.bankovniUcet &&
      novaFirma.bankovniUcet !== "" &&
      novaFirma.ulice &&
      novaFirma.ulice !== "" &&
      novaFirma.mesto &&
      novaFirma.mesto !== "" &&
      novaFirma.psc &&
      novaFirma.psc !== "" &&
      novaFirma.emailKomunikace &&
      novaFirma.emailKomunikace !== "" &&
      novaFirma.telefonKomunikace &&
      novaFirma.telefonKomunikace !== ""
    );
  };

  render() {
    const user = authService.getCurrentUser();
    const { novaFirma } = this.state;
    return (
      <React.Fragment>
        {user && user.login ? (
          <div className="row pt-3">
            <div className="col-12">
              <h4>Údaje o společnosti</h4>
              <p>
                Po registraci firmy si můžete naplnit do aplikace seznam Vašich
                zaměstnanců. Mezitím se Vám ozveme a dořešíme podmínky a detaily
                smlouvy řešící poskytování této služby.
              </p>
            </div>
            <div className="col-md-4">
              <small>Název společnosti</small>
              <input
                type="text"
                className="form-control"
                name="nazevSpolecnosti"
                value={novaFirma.nazevSpolecnosti}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>IČ</small>
              <input
                type="text"
                className="form-control"
                name="ic"
                value={novaFirma.ic}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>Bankovní účet</small>
              <input
                type="text"
                className="form-control"
                name="bankovniUcet"
                value={novaFirma.bankovniUcet}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>Ulice</small>
              <input
                type="text"
                className="form-control"
                name="ulice"
                value={novaFirma.ulice}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>Město</small>
              <input
                type="text"
                className="form-control"
                name="mesto"
                value={novaFirma.mesto}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>PSČ</small>
              <input
                type="text"
                className="form-control"
                name="psc"
                value={novaFirma.psc}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>E-mail</small>
              <input
                type="text"
                className="form-control"
                name="emailKomunikace"
                value={novaFirma.emailKomunikace}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <small>Telefon</small>
              <input
                type="text"
                className="form-control"
                name="telefonKomunikace"
                value={novaFirma.telefonKomunikace}
                onChange={(e) => this.zmenaFirma(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-4 text-center pt-4">
              <Button onClick={this.novaFirma} disabled={!this.checkFirma()}>
                Registrovat firmu
              </Button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <p>
                Pro registraci firmy k testům zaměstnanců na Covid-19 je potřeba
                nejprve vytvořit Váš login pro správce profilu firmy. Pokud
                login máte, přihlaste se.
              </p>
            </div>
            <div className="col-md-6">
              <h4>Přihlásit se</h4>
              <UserLogin />
            </div>
            <div className="col-md-6">
              <h4>Registrace uživatele</h4>
              <UserRegistrace />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default FiremniTestovani;
