import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (expectedError && error.response.status === 401) {
    localStorage.removeItem("token");
    window.location = "/";
  }

  if (expectedError && error.response.headers["x-message"]) {
    toast.error(error.response.headers["x-message"]);
  }
  if (!expectedError) {
    toast.error("CRM neodpovídá...");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function post(url, dt, pr) {
  const ret = axios.post(url, dt, pr);
  return ret;
}

export default {
  get: axios.get,
  post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
