import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import authService from "../../services/authService";
import { Button, Tab, Tabs, Table, Modal } from "react-bootstrap";
import UserLogin from "../common/UserLogin";
import { toast } from "react-toastify";
import CovidTerminy from "./Terminy";

class FiremniTestovaniAdmin extends Component {
  state = { novyLogin: {} };

  componentDidMount = () => {
    const user = authService.getCurrentUser();
    this.setState({ user });
    if (user && user.login) {
      this.nactiFirmy();
    } else {
      this.setState({ loaded: true });
    }
    this.nahrajPojistovny();
  };

  nahrajPojistovny = async () => {
    const pojistovny = await httpService.post(
      config.apiEndpoint + "covid/zdravotniPojistovny.php"
    );
    this.setState({ pojistovny: pojistovny.data.pojistovny });
  };

  nactiFirmy = async () => {
    const firmy = await httpService.post(
      config.apiEndpoint + "firmy/vypisWeb.php"
    );
    await this.setState({
      loaded: true,
      firmy: firmy.data.firmy,
      firma_id:
        firmy.data.firmy && firmy.data.firmy[0] && firmy.data.firmy[0].firma_id,
      novyLogin: {
        ...this.state.novyLogin,
        firma_id:
          firmy.data.firmy &&
          firmy.data.firmy[0] &&
          firmy.data.firmy[0].firma_id,
      },
    });
    this.nactiOsoby();
    this.nactiStats();
  };

  zmenaFirmy = async (firma_id) => {
    await this.setState({
      firma_id,
      osoba_id: null,
      novyLogin: { ...this.state.novyLogin, firma_id },
    });
    this.nactiOsoby();
    this.nactiStats();
  };

  nactiOsoby = async () => {
    const { firma_id } = this.state;
    const osoby = await httpService.post(
      config.apiEndpoint + "firmy/osobyFirma.php",
      { firma_id }
    );
    this.setState({ osoby: osoby.data.osoby });
  };

  nactiStats = async () => {
    const { firma_id } = this.state;
    const stats = await httpService.post(
      config.apiEndpoint + "firmy/stats.php",
      { firma_id }
    );
    this.setState({ stats: stats.data.stats });
  };

  novyLoginZmena = (attr, val) => {
    let { novyLogin } = this.state;
    novyLogin[attr] = val;
    this.setState({ novyLogin });
  };

  novyLogin = async () => {
    const { novyLogin, firma_id } = this.state;
    const token = await httpService.post(
      config.apiEndpoint + "user/registraceOsoba.php",
      { novyLogin }
    );
    if (token.data.error) {
      toast.error(token.data.error);
    } else {
      this.nactiOsoby();
      this.nactiStats();
      this.setState({ novyLoginShow: false, novyLogin: { firma_id } });
    }
  };

  checkNovyLogin = () => {
    const { novyLogin } = this.state;
    return (
      novyLogin.login &&
      novyLogin.login !== "" &&
      novyLogin.jmeno &&
      novyLogin.jmeno !== "" &&
      novyLogin.prijmeni &&
      novyLogin.prijmeni !== "" &&
      novyLogin.ulice &&
      novyLogin.ulice !== "" &&
      novyLogin.mesto &&
      novyLogin.mesto !== "" &&
      novyLogin.psc &&
      novyLogin.psc !== "" &&
      novyLogin.mobil &&
      novyLogin.mobil !== "" &&
      novyLogin.rodneCislo &&
      novyLogin.rodneCislo !== "" &&
      novyLogin.datumNarozeni &&
      novyLogin.datumNarozeni !== "" &&
      novyLogin.zdravotniPojistovna &&
      novyLogin.zdravotniPojistovna !== ""
    );
  };

  render() {
    const {
      user,
      loaded,
      firmy,
      firma_id,
      osoba_id,
      osoby,
      stats,
      novyLoginShow,
      novyLogin,
      pojistovny,
      showReport,
    } = this.state;
    let firma = {};
    if (firma_id) {
      firma = firmy.find((f) => f.firma_id === firma_id);
    }
    return (
      <React.Fragment>
        {loaded ? (
          user && firmy && firmy.length > 0 ? (
            <div>
              {firmy && firmy.length > 1 && (
                <select
                  className="form-control mt-3"
                  value={firma_id}
                  onChange={(e) => this.zmenaFirmy(e.target.value)}
                >
                  {firmy.map((f) => (
                    <option value={f.firma_id}>{f.nazev}</option>
                  ))}
                </select>
              )}
              <h4 className="pt-3">Firma: {firma.nazev}</h4>
              <Modal
                show={novyLoginShow}
                onHide={() => this.setState({ novyLoginShow: false })}
              >
                <Modal.Header>Registrace nového zaměstnance</Modal.Header>
                <Modal.Body>
                  <small>E-mail</small>
                  <input
                    type="text"
                    className="form-control"
                    name="login"
                    required={true}
                    value={novyLogin.login}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Jméno</small>
                  <input
                    type="text"
                    className="form-control"
                    name="jmeno"
                    value={novyLogin.jmeno}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Příjmeni</small>
                  <input
                    type="text"
                    className="form-control"
                    name="prijmeni"
                    value={novyLogin.prijmeni}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Ulice a čp.</small>
                  <input
                    type="text"
                    className="form-control"
                    name="ulice"
                    value={novyLogin.ulice}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Město</small>
                  <input
                    type="text"
                    className="form-control"
                    name="mesto"
                    value={novyLogin.mesto}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>PSČ</small>
                  <input
                    type="text"
                    className="form-control"
                    name="psc"
                    value={novyLogin.psc}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Mobilní telefon</small>
                  <input
                    type="text"
                    className="form-control"
                    name="mobil"
                    value={novyLogin.mobil}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Rodné číslo (bez lomítka)</small>
                  <input
                    type="text"
                    className="form-control"
                    name="rodneCislo"
                    value={novyLogin.rodneCislo}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Datum narození</small>
                  <input
                    type="date"
                    className="form-control"
                    name="datumNarozeni"
                    value={novyLogin.datumNarozeni}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  />
                  <small>Zdravotní pojišťovna</small>
                  <select
                    name="zdravotniPojistovna"
                    className="form-control"
                    value={novyLogin.zdravotniPojistovna}
                    onChange={(e) =>
                      this.novyLoginZmena(e.target.name, e.target.value)
                    }
                  >
                    {(!novyLogin.zdravotniPojistovna ||
                      novyLogin.zdravotniPojistovna === "") && (
                      <option value="">Zvolte pojišťovnu</option>
                    )}
                    {pojistovny &&
                      pojistovny.map((p) => (
                        <option value={p.pojistovna_id}>
                          {p.pojistovna_id} - {p.nazev}
                        </option>
                      ))}
                  </select>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={!this.checkNovyLogin()}
                    onClick={this.novyLogin}
                  >
                    Uložit zaměstnance
                  </Button>
                </Modal.Footer>
              </Modal>
              <Tabs>
                <Tab eventKey="registrace-k-testu" title="Registrace k testu">
                  <div className="row pt-3">
                    <div className="col-md-4">
                      <h5>Krok 1: Volba zaměstnance</h5>
                      <div className="text-center pt-3 pb-3">
                        <Button
                          onClick={() => this.setState({ novyLoginShow: true })}
                        >
                          Nový zaměstnanec
                        </Button>
                      </div>
                      {osoby && osoby.length > 0 ? (
                        <ul className="zamci">
                          {osoby.map((o) => (
                            <li
                              key={o.login}
                              className={
                                o.login == osoba_id ? "selected" : null
                              }
                            >
                              <Button
                                variant="link"
                                onClick={() =>
                                  this.setState({ osoba_id: o.login })
                                }
                              >
                                {o.jmeno}
                              </Button>
                              <div>*{o.datumNarozeni}</div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="alert alert-danger">
                          Neevidujeme žádné zaměstnance
                        </div>
                      )}
                    </div>
                    <div className="col-md-8">
                      <h5>Krok 2: Volba volného termínu</h5>
                      <CovidTerminy
                        firma_id={firma_id}
                        login={osoba_id}
                        onChange={this.nactiStats}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="prehled" title="Přehled testů a výsledků">
                  {stats && stats.length > 0 ? (
                    <div className="pt-3 pb-3">
                      <Modal
                        show={showReport}
                        onHide={() => this.setState({ showReport: false })}
                        className="modal-90w"
                      >
                        <Modal.Header closeButton>Přehled testů</Modal.Header>
                        <Modal.Body>
                          <iframe
                            src={
                              config.apiEndpoint +
                              "covid/pdfPrehled.php?token=" +
                              authService.getJwt()
                            }
                            style={{
                              height: "80vh",
                              width: "100%",
                              border: "none",
                            }}
                          />
                        </Modal.Body>
                      </Modal>
                      <Button
                        onClick={() => this.setState({ showReport: true })}
                        className="mb-3"
                      >
                        Export výsledků
                      </Button>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Jméno</th>
                            <th>Počet testů</th>
                            <th>Poslední test</th>
                            <th>Přehled testů</th>
                            <th>Naplánované testy</th>
                            <th>Plán testů</th>
                            <th>Neúčasti na objednaných testech</th>
                            <th>Přehled testů</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stats &&
                            stats.length > 0 &&
                            stats.map((p, index) => (
                              <tr key={index}>
                                <td>{p.jmeno}</td>
                                {p.pocetNavstev > 0 ? (
                                  <td className="text-right">
                                    {p.pocetNavstev}
                                  </td>
                                ) : (
                                  <td
                                    className="text-center text-danger"
                                    colSpan={3}
                                  >
                                    Ještě netestován
                                  </td>
                                )}
                                {p.pocetNavstev > 0 && (
                                  <td>
                                    {p.posledniNavsteva}
                                    <div
                                      className={
                                        p.dnyOdPosledniNavstevy > 7
                                          ? "text-danger"
                                          : p.dnyOdPosledniNavstevy >= 5
                                          ? "text-warning"
                                          : "text-success"
                                      }
                                    >
                                      před {p.dnyOdPosledniNavstevy} dny
                                    </div>
                                  </td>
                                )}
                                {p.pocetNavstev > 0 && (
                                  <td
                                    className="text-center"
                                    style={{ fontSize: "0.8em" }}
                                  >
                                    {p.datumyNavstev &&
                                      p.datumyNavstev.map((d) => (
                                        <div key={d}>{d}</div>
                                      ))}
                                  </td>
                                )}
                                {p.pocetPlan > 0 ? (
                                  <td className="text-right">{p.pocetPlan}</td>
                                ) : (
                                  <td
                                    colSpan={2}
                                    className="text-center text-danger"
                                  >
                                    Bez plánovaných testů
                                  </td>
                                )}
                                {p.pocetPlan > 0 && (
                                  <td className="text-center">
                                    {p.datumyPlan &&
                                      p.datumyPlan.map((d) => (
                                        <div key={d}>{d}</div>
                                      ))}
                                  </td>
                                )}
                                {p.pocetNeucasti > 0 ? (
                                  <td className="text-right">
                                    {p.pocetNeucasti}
                                  </td>
                                ) : p.pocetNavstev > 0 ? (
                                  <td
                                    className="text-success text-center"
                                    colSpan={2}
                                  >
                                    Vždy dorazil
                                  </td>
                                ) : (
                                  <td
                                    className="text-warning text-center"
                                    colSpan={2}
                                  >
                                    Kde nic není... :o)
                                  </td>
                                )}
                                {p.pocetNeucasti > 0 && (
                                  <td className="text-center">
                                    {p.datumyNeucasti &&
                                      p.datumyNeucasti.map((d) => (
                                        <div key={d}>{d}</div>
                                      ))}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="mt-3 alert alert-danger">
                      Nic tu není...
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          ) : (
            <React.Fragment>
              {user && user.login && (
                <div className="mt-3 alert alert-danger">
                  <p>
                    Nemáte registrovanou žádnou firmu. Musíte projít
                    registračním procesem...
                  </p>
                  <p>
                    <Button
                      onClick={() => {
                        window.location = "#/registrace/registrace-firmy";
                        window.location.reload();
                      }}
                      variant="danger"
                    >
                      Registrace firmy
                    </Button>
                  </p>
                </div>
              )}
              {!(user && user.login) && (
                <div className="pt-3">
                  <h4>Přihlásit se</h4>
                  <UserLogin />
                </div>
              )}
            </React.Fragment>
          )
        ) : (
          "Loading..."
        )}
      </React.Fragment>
    );
  }
}

export default FiremniTestovaniAdmin;
