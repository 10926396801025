import { configure } from "@testing-library/react";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import CovidTerminy from "../Covid/Terminy";

class RegistraceVerejnost extends Component {
  state = {
    novyLogin: {
      firma_id: config.firma_id,
    },
  };

  componentDidMount = () => {
    this.nahrajPojistovny();
    this.setState({ user: authService.getCurrentUser() });
  };

  nahrajPojistovny = async () => {
    const pojistovny = await httpService.post(
      config.apiEndpoint + "covid/zdravotniPojistovny.php"
    );
    this.setState({ pojistovny: pojistovny.data.pojistovny });
  };

  novyLoginZmena = (attr, val) => {
    let { novyLogin } = this.state;
    novyLogin[attr] = val;
    this.setState({ novyLogin });
  };

  novyLogin = async () => {
    const { novyLogin } = this.state;
    const token = await httpService.post(
      config.apiEndpoint + "user/registraceOsoba.php",
      { novyLogin }
    );
    if (token.data.error) {
      toast.error(token.data.error);
    } else {
      localStorage.setItem("token", token.data.token);
      this.setState({ user: authService.getCurrentUser() });
      window.location.reload();
    }
  };

  checkNovyLogin = () => {
    const { novyLogin } = this.state;
    return (
      novyLogin.login &&
      novyLogin.login !== "" &&
      novyLogin.jmeno &&
      novyLogin.jmeno !== "" &&
      novyLogin.prijmeni &&
      novyLogin.prijmeni !== "" &&
      novyLogin.ulice &&
      novyLogin.ulice !== "" &&
      novyLogin.mesto &&
      novyLogin.mesto !== "" &&
      novyLogin.psc &&
      novyLogin.psc !== "" &&
      novyLogin.mobil &&
      novyLogin.mobil !== "" &&
      novyLogin.rodneCislo &&
      novyLogin.rodneCislo !== "" &&
      novyLogin.datumNarozeni &&
      novyLogin.datumNarozeni !== "" &&
      novyLogin.zdravotniPojistovna &&
      novyLogin.zdravotniPojistovna !== ""
    );
  };

  render() {
    const { pojistovny, novyLogin, user } = this.state;
    return (
      <React.Fragment>
        <p>
          Zde se můžete registrovat k antigennímu testu na Covid-19. V prvním
          kroku vyplňte osobní údaje. Upozorňujeme, že všechny údaje vč. čísla
          pojištěnce jsou nezbytnou součástí registrace. Bez těchto údajů nelze
          test vykázat k úhradě pojišťovně. Děkujeme za pochopení.
        </p>
        <div className="row">
          <div className="col-md-4">
            <h5>Krok 1: Osobní údaje</h5>
            {user && user.login ? (
              <React.Fragment>
                <small>E-mail</small>
                <div className="pb-3">{user.login}</div>
                <small>Jméno</small>
                <div className="pb-3">{user.jmeno}</div>
                <small>Adresa</small>
                <div className="pb-3">
                  {user.ulice}
                  <br />
                  {user.psc} {user.mesto}
                </div>
                <small>Mobilní telefon</small>
                <div className="pb-3">{user.mobil}</div>
                <small>Rodné číslo (bez lomítka)</small>
                <div className="pb-3">{user.rodneCislo}</div>
                <small>Datum narození</small>
                <div className="pb-3">{user.datumNarozeni}</div>
                <small>Zdravotní pojišťovna</small>
                <div className="pb-3">{user.zdravotniPojistovna}</div>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      localStorage.removeItem("token");
                      this.setState({ user: null });
                      window.location.reload();
                    }}
                  >
                    Zadat jinou osobu
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <small>E-mail*</small>
                <input
                  type="text"
                  className="form-control"
                  name="login"
                  required={true}
                  value={novyLogin.login}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Heslo</small>
                <input
                  type="password"
                  className="form-control"
                  name="pass"
                  value={novyLogin.pass}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <p
                  style={{
                    fontSize: "0.8em",
                    borderBottom: "solid 1px #ddd",
                    padding: "10px 0px",
                  }}
                >
                  Pokud uvažujete o pravidelném testování, vytvoříme Vám účet
                  pro přihlašování. Pokud účet nechcete, heslo nevyplňujte.
                </p>
                <small>Jméno*</small>
                <input
                  type="text"
                  className="form-control"
                  name="jmeno"
                  value={novyLogin.jmeno}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Příjmeni*</small>
                <input
                  type="text"
                  className="form-control"
                  name="prijmeni"
                  value={novyLogin.prijmeni}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Ulice a čp.*</small>
                <input
                  type="text"
                  className="form-control"
                  name="ulice"
                  value={novyLogin.ulice}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Město*</small>
                <input
                  type="text"
                  className="form-control"
                  name="mesto"
                  value={novyLogin.mesto}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>PSČ*</small>
                <input
                  type="text"
                  className="form-control"
                  name="psc"
                  value={novyLogin.psc}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Mobilní telefon*</small>
                <input
                  type="text"
                  className="form-control"
                  name="mobil"
                  value={novyLogin.mobil}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Rodné číslo (bez lomítka)*</small>
                <input
                  type="text"
                  className="form-control"
                  name="rodneCislo"
                  value={novyLogin.rodneCislo}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Datum narození*</small>
                <input
                  type="date"
                  className="form-control"
                  name="datumNarozeni"
                  value={novyLogin.datumNarozeni}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                />
                <small>Zdravotní pojišťovna*</small>
                <select
                  name="zdravotniPojistovna"
                  className="form-control"
                  value={novyLogin.zdravotniPojistovna}
                  onChange={(e) =>
                    this.novyLoginZmena(e.target.name, e.target.value)
                  }
                >
                  {(!novyLogin.zdravotniPojistovna ||
                    novyLogin.zdravotniPojistovna === "") && (
                    <option value="">Zvolte pojišťovnu</option>
                  )}
                  {pojistovny &&
                    pojistovny.map((p) => (
                      <option value={p.pojistovna_id}>
                        {p.pojistovna_id} - {p.nazev}
                      </option>
                    ))}
                </select>
                {!this.checkNovyLogin() && (
                  <p className="pt-3 text-danger" style={{ fontSize: "0.8em" }}>
                    Vyplňte všechny údaje označené *. Jinak není možné
                    pokračovat volbou termínu. Pokud do formuláře nevyplníte
                    korektní údaje, bude Vaše rezervace zrušena.
                  </p>
                )}
                <p className="text-center pt-3">
                  <Button
                    disabled={!this.checkNovyLogin()}
                    onClick={this.novyLogin}
                  >
                    Uložit údaje a pokračovat volbou termínu
                  </Button>
                </p>
              </React.Fragment>
            )}
          </div>
          <div className="col-md-8">
            <h5>Krok 2: Volba volného termínu</h5>
            {((user && user.firma_id) || (config && config.firma_id)) && (
              <CovidTerminy
                firma_id={
                  user && user.firma_id ? user.firma_id : config.firma_id
                }
                login={user && user.login}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistraceVerejnost;
