import jwtDecode from "jwt-decode";
import http from "./httpService";
import { toast } from "react-toastify";
import config from "../config.json";

const apiEndpoint = config.apiEndpoint + "user/auth.php";
const authRefresh = config.apiEndpoint + "user/authRefresh.php";
const tokenKey = "token";
const firma_id = "firma_id";

http.setJwt(getJwt());

export async function login(login, password, showerror = false) {
  try {
    localStorage.removeItem(firma_id);
    const { data: jwt, headers } = await http.post(apiEndpoint, {
      login,
      password
    });
    if (headers["x-error"]) {
      localStorage.removeItem(tokenKey);
      throw headers["x-error"];
    }
    localStorage.setItem(tokenKey, jwt);
    const user = jwtDecode(jwt);
    if (showerror) {
      window.location.reload();
    }
  } catch (error) {
    if (showerror) {
      toast.error(error);
    } else {
      console.log(error);
    }
  }
}

export async function loginRefresh() {
  console.log("refresh");
  if (localStorage.getItem("stayConnected") === "true") {
    await http
      .post(authRefresh)
      .then(response => {
        const { data: jwt } = response;
        localStorage.setItem(tokenKey, jwt);
        setTimeout(loginRefresh, 1000 * 60 * 15);
      })
      .catch(error => {
        console.log("login error");
        setTimeout(loginRefresh, 1000 * 30);
      });
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function aktualniFirma() {
  const user = getCurrentUser();
  const firma_id = localStorage.getItem("firma_id");
  let firma = {};
  if (firma_id && user.firmy) {
    firma = user.firmy.filter(f => f.firma_id === firma_id)[0];
  }
  return firma;
}

export function getLang() {
  const user = getCurrentUser();
  const firma_id = localStorage.getItem("firma_id");
  let firma = {};
  if (firma_id && user.firmy) {
    firma = user.firmy.filter(f => f.firma_id === firma_id)[0];
  }
  //return firma.agenda_id;
  const agenda_id = firma.agenda_id ? firma.agenda_id : "cz";
  const lang = config.lang[agenda_id];
  return lang;
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginRefresh,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  aktualniFirma,
  getLang
};
