import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import FiremniTestovaniAdmin from "../Covid/FiremniTestovani";
import NovinkyPrehled from "../Novinky/NovinkyPrehled";
import FiremniTestovani from "../Registrace/FiremniTestovani";
import RegistraceVerejnost from "../Registrace/RegistraceOsoba";

class HomePage extends Component {
  state = {};
  render() {
    let { tab } = this.props.match.params;
    tab = tab ? tab : "verejnost";
    return (
      <React.Fragment>
        <div
          className="header"
          style={{
            backgroundImage: "url('bg.jpg')",
            backgroundSize: "cover",
            color: "#fff",
          }}
        >
          <div className="container">
            <h3>Registrace na antigenní testy</h3>
          </div>
        </div>
        <div className="container pt-3 pb-5">
          <NovinkyPrehled />
          <Tabs defaultActiveKey={tab}>
            <Tab
              eventKey="verejnost"
              title="Veřejnost"
              onEnter={() => (window.location = "#/registrace/verejnost")}
            >
              <div className="pt-3">
                <RegistraceVerejnost />
              </div>
            </Tab>
            <Tab
              eventKey="firemni-testovani"
              title="Firemní testování"
              onEnter={() =>
                (window.location = "#/registrace/firemni-testovani")
              }
            >
              <FiremniTestovaniAdmin />
            </Tab>
            <Tab
              eventKey="registrace-firmy"
              title="Registrace firmy k testování"
              onEnter={() =>
                (window.location = "#/registrace/registrace-firmy")
              }
            >
              <FiremniTestovani />
            </Tab>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
