import React, { Component } from "react";
import { Button } from "react-bootstrap";
import config from "../../config.json";
import httpService from "../../services/httpService";
import { toast } from "react-toastify";

class UserRegistrace extends Component {
  state = {
    novyLogin: {
      firma_id: config.firma_id,
    },
  };

  novyLoginZmena = (attr, val) => {
    let { novyLogin } = this.state;
    novyLogin[attr] = val;
    this.setState({ novyLogin });
  };

  novyLogin = async () => {
    const { novyLogin } = this.state;
    const token = await httpService.post(
      config.apiEndpoint + "user/registraceOsoba.php",
      { novyLogin }
    );
    if (token.data.error) {
      toast.error(token.data.error);
    } else {
      localStorage.setItem("token", token.data.token);
      window.location.reload();
    }
  };

  checkNovyLogin = () => {
    const { novyLogin } = this.state;
    return (
      novyLogin.login &&
      novyLogin.login !== "" &&
      novyLogin.jmeno &&
      novyLogin.jmeno !== "" &&
      novyLogin.prijmeni &&
      novyLogin.prijmeni !== "" &&
      novyLogin.pass &&
      novyLogin.pass !== "" &&
      novyLogin.mobil &&
      novyLogin.mobil !== ""
    );
  };

  render() {
    const { novyLogin } = this.state;
    return (
      <React.Fragment>
        <small>E-mail</small>
        <input
          type="text"
          className="form-control"
          name="login"
          required={true}
          value={novyLogin.login}
          onChange={(e) => this.novyLoginZmena(e.target.name, e.target.value)}
        />
        <small>Heslo</small>
        <input
          type="password"
          className="form-control"
          name="pass"
          value={novyLogin.pass}
          onChange={(e) => this.novyLoginZmena(e.target.name, e.target.value)}
        />
        <small>Jméno</small>
        <input
          type="text"
          className="form-control"
          name="jmeno"
          value={novyLogin.jmeno}
          onChange={(e) => this.novyLoginZmena(e.target.name, e.target.value)}
        />
        <small>Příjmeni</small>
        <input
          type="text"
          className="form-control"
          name="prijmeni"
          value={novyLogin.prijmeni}
          onChange={(e) => this.novyLoginZmena(e.target.name, e.target.value)}
        />
        <small>Mobilní telefon</small>
        <input
          type="text"
          className="form-control"
          name="mobil"
          value={novyLogin.mobil}
          onChange={(e) => this.novyLoginZmena(e.target.name, e.target.value)}
        />
        <p className="text-center pt-3">
          <Button disabled={!this.checkNovyLogin()} onClick={this.novyLogin}>
            Vytvořit login
          </Button>
        </p>
      </React.Fragment>
    );
  }
}

export default UserRegistrace;
