import React, { Component } from "react";
import { Button } from "react-bootstrap";
import authService from "../../services/authService";

class UserLogin extends Component {
  state = {};
  render() {
    const { login, pass } = this.state;
    return (
      <React.Fragment>
        <small>Login (e-mail)</small>
        <input
          type="text"
          className="form-control"
          value={login}
          onChange={(e) => this.setState({ login: e.target.value })}
        />
        <small>Heslo</small>
        <input
          type="password"
          className="form-control"
          value={pass}
          onChange={(e) => this.setState({ pass: e.target.value })}
        />
        <p className="text-center mt-3">
          <Button
            disabled={!(login && pass && login !== "" && pass !== "")}
            onClick={() => authService.login(login, pass, true)}
          >
            Přihlásit
          </Button>
        </p>
      </React.Fragment>
    );
  }
}

export default UserLogin;
